import React from "react";
import {Link, NavLink} from "react-router-dom";
import logo from "../../src/images/pbad.png"

export default function Header() {
    return (
        <header className="header mb-5">
            <nav className="nav">
                <Link to="/" className="site-title">
                    <img src={logo} alt=''/>
                </Link>
                Baduchowski Challenge Draft 2024
                <span>
                            <i className='fa fa-user icon-circle'/>
                </span>
            </nav>
            <div className="menu">
                <li>
                    <NavLink to="/">Zawodnicy</NavLink>
                </li>
                <li>
                    <NavLink to="/wyniki">Spotkania</NavLink>
                </li>
            </div>
        </header>
    )
}